
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.a-link {
    color: inherit;
}

.search-input {
    width: 300px !important;
    margin-bottom: 20px;

    ::v-deep .el-input-group__append {
        padding: 0 8px;
        background: #2dc079;

        .el-button {
            i {
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}

.op-name {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
